/* fonts */
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@500&family=Montserrat:wght@500&display=swap");

.font-montserrat {
  font-family: "Montserrat", sans-serif;
}
.font-leagueSpartan {
  font-family: "League Spartan", sans-serif;
}
/* general */
img,
video {
  max-width: 200%;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0);
  border-radius: 20px;
}

/* Hides Scroll arrows in Number type inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/*Hides the blue highlight on mobile devices*/
* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  scroll-behavior: smooth;
}
input, textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

/* Navbar */
svg {
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
}
.active svg {
  transform: rotate(90deg);
}
path {
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dasharray 500ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 500ms cubic-bezier(0.4, 0, 0.2, 1);
}
path:nth-child(1) {
  transform-origin: 36% 40%;
}
path:nth-child(2) {
  stroke-dasharray: 29 299;
}
path:nth-child(3) {
  transform-origin: 35% 63%;
}
path:nth-child(4) {
  stroke-dasharray: 29 299;
}
path:nth-child(5) {
  transform-origin: 61% 52%;
}
path:nth-child(6) {
  transform-origin: 62% 52%;
}
.active path:nth-child(1) {
  transform: translateX(9px) translateY(1px) rotate(45deg);
}
.active path:nth-child(2) {
  stroke-dasharray: 225 299;
  stroke-dashoffset: -72px;
}
.active path:nth-child(3) {
  transform: translateX(9px) translateY(1px) rotate(-45deg);
}
.active path:nth-child(4) {
  stroke-dasharray: 225 299;
  stroke-dashoffset: -72px;
}
.active path:nth-child(5) {
  transform: translateX(9px) translateY(1px) rotate(-45deg);
}
.active path:nth-child(6) {
  transform: translateX(9px) translateY(1px) rotate(45deg);
}
/* Navbar end */

.HeroMapImage {
  animation: zoomIn 1250ms ease-in-out;
}
@keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.moveCar {
  animation: moveCar 1250ms ease-in-out forwards;
}
@keyframes moveCar {
  from {
    transform: translateX(700px);
  }
  to {
    transform: translateX(-90px);
  }
}

.moveWheel {
  animation: moveWheel 1250ms ease-in-out forwards;
}
@keyframes moveWheel {
  from {
    transform: translateX(700px);
    /* transform: rotate(360deg); */
  }
  to {
    transform: translateX(-90px);
    /* transform: rotate(0deg); */
  }
}
.spin {
  animation: spin 1250ms linear forwards;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* glass background */
/* This was made with GlassGenerator.netlify.app */

.glass-container {
  /* display: flex; */
  justify-content: center;
  /* align-items: center; */
  /* gap: 20px; */
  /* border-radius: 10px; */
  backdrop-filter: blur(5px);
  background-color: rgba(0, 191, 255, 0.075);
  /* box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px; */
  /* border: 2px rgba(255, 255, 255, 0.4) solid;
  border-bottom: 2px rgba(40, 40, 40, 0.35) solid;
  border-right: 2px rgba(40, 40, 40, 0.35) solid; */
}
